import logo from './logo.svg';
import './App.css';

function App() {
    return (
        <div className="container vh-100">
            <div className="text-center">
                <img src={logo} className="h-50"/>
                <h1>Coming Soon</h1>
                <p>The App is Currently Under Construction</p>
            </div>
        </div>
    );
}

export default App;
